<template>
  <component
    :is="props.variant"
    :class="{
      'overflow-hidden text-ellipsis whitespace-nowrap': !props.disableEllipsis,
    }"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
export interface TypgraphyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p";
  disableEllipsis?: boolean;
}

const props = withDefaults(defineProps<TypgraphyProps>(), {
  variant: "p",
  disableEllipsis: false,
});
</script>
