<template>
  <div
    class="relative rounded-[inherit] overflow-hidden cursor-pointer isolate [&>*]:relative [&>*]:z-1 [& *]:transition-colors hover:text-white"
  >
    <div
      class="__ripple"
      :style="{
        left: targetPoint[0],
        top: targetPoint[1],
        '--size': props.dotSize,
        '--growth': props.dotGrowth,
        '--transition-duration': props.transitionDuration * 2,
        '--initial-opacity': props.initialOpacity,
      }"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
interface RippleBoxProps {
  dotGrowth?: number;
  dotSize?: `${number}${"%" | "rem" | "em" | "px" | "ch"}`;
  transitionDuration?: number;
  initialOpacity?: number;
  ripplePosition?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "center-left"
    | "center"
    | "center-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
}
const CSS_POSITIONS: Record<
  NonNullable<RippleBoxProps["ripplePosition"] | "undefined">,
  string
> = {
  undefined: "0 0",
  "top-left": "0 0",
  "top-center": "50% 0",
  "top-right": "100% 0",
  "center-left": "0 50%",
  center: "50% 50%",
  "center-right": "100% 50%",
  "bottom-left": "0 100%",
  "bottom-center": "50% 100%",
  "bottom-right": "100% 100%",
} as const;

const props = withDefaults(defineProps<RippleBoxProps>(), {
  ripplePosition: "center",
  dotSize: "1rem",
  dotGrowth: 1000,
  initialOpacity: 1,
  transitionDuration: 1000,
});

const ripplePosition = ref<RippleBoxProps["ripplePosition"]>("center");
ripplePosition.value = props.ripplePosition;

type Numberish = string | number;
type Vector2 = [Numberish, Numberish];
const targetPoint = ref<Vector2>([0, 0]);
targetPoint.value = CSS_POSITIONS[
  String(ripplePosition.value) as keyof typeof CSS_POSITIONS
].split(" ") as Vector2;
</script>

<style scoped lang="scss">
.__ripple {
  @apply bg-secondary;

  transition: all 240ms ease, opacity 160ms linear, color 20ms ease;

  position: absolute;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);

  transform: scale(0);
  opacity: var(--initial-opacity, 0);
  z-index: 0;
  pointer-events: none;
}

*:hover > .__ripple,
*:focus-visible > .__ripple {
  transition: all calc(var(--transition-duration) * 1ms) ease,
    opacity calc(var(--transition-duration) * 1ms) linear, color 120ms ease;

  transform: scale(var(--growth));
  opacity: 1;
  border-radius: 100%;
}
</style>
